

export const categorySelector = (state: any) => {
  const {
    totalCategories,
    categories,
    category,
    page,
    limit,
    loadingStatus,
    getCategoriesError,
    addCategoryError,
    deleteCategoryError
  } = state.category
  return {
    totalCategories,
    categories,
    category,
    page,
    limit,
    loadingStatus,
    getCategoriesError,
    addCategoryError,
    deleteCategoryError
  }
}