
import { ReactComponent as listIconSvg } from "../images/list.svg"
import { ReactComponent as UserSvg } from "../images/users-svgrepo-com.svg"
import { ReactComponent as ImagesSvg } from "../images/images-svgrepo-com.svg"
import { ReactComponent as BuildingsIconSvg } from "../images/buildings-building-construction-estate-svgrepo-com.svg"


export function NavigationLinkData() {

  const navLinkData = [
    { link: `/dashboard`, label: "Dashboard", icon: listIconSvg },

    { link: `/users`, label: "Users", icon: UserSvg },

    { link: `/properties`, label: "Properties", icon: BuildingsIconSvg },

    { link: `/categories`, label: "Categories", icon: BuildingsIconSvg },

    { link: `/subscribers`, label: "Subscribers", icon: UserSvg },
  ];

  return {
    navLinkData,
  };
}
