import React from "react";
import { Box, Grid } from "@mui/material";
import { ReactComponent as CheckSvg } from "../../images/check-circle-svgrepo-com.svg"
import { ReactComponent as OpenInNewTabSvg } from "../../images/icons8-new-tab.svg"
import useAuth from "../../utils";
import { useDispatch } from "react-redux";
import { updateDocumentVerificationStatusThunk } from "../../Redux/Thunks/userThunk";
import { toast } from "react-toastify";


export const ViewDocuments = ({ label, field_name, user_id, existingDocuments, onStatusUpdation , folder}: any) => {

    const { checkLoginToken, logoutUser } = useAuth();

    const dispatch = useDispatch()

    async function updateDocumentVerificationStatus(data: any) {

        let token = checkLoginToken()
        let response: any = await dispatch(updateDocumentVerificationStatusThunk(
            {
                user_id: user_id,
                field_name: field_name,
                document_id: data.document_id,
                status: data.status,
                token: token
            }
        ))

        if (response.payload.response && response.payload.response.status) {
            if (response.payload.response.status == 401) {

            }
            toast.error(response.payload.response.data.message)
        }
        else {
            onStatusUpdation()

        }

    }


    return (
        <Grid container spacing={2} sx={{ mb: 2 }}>
            <h6>{label}</h6>
            {
                existingDocuments ? existingDocuments.map((ele: any, index: number) => {
                    if (ele.document.split(".")[1].toLowerCase() == "pdf") {
                        return (
                            <Grid item sm={6} md={3}>
                                <span className="close-btn"

                                >
                                    <CheckSvg fill={ele.verification_status ? "green" : "red"} onClick={() => {
                                        updateDocumentVerificationStatus({
                                            document_id: ele._id,
                                            status: !ele.verification_status
                                        })
                                    }} />

                                </span>
                                <a href={`${process.env.REACT_APP_BACKEND_BASE_URL}/uploads/${folder}/original/${ele.document}`} target="_blank" onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation()
                                }}>

                                    <span className="open-btn"
                                    >
                                        <OpenInNewTabSvg onClick={() => {
                                            window.open(`${process.env.REACT_APP_BACKEND_BASE_URL}/uploads/${folder}/original/${ele.document}`, '_blank', 'noopener,noreferrer');
                                        }} />

                                    </span>
                                    <p className="img-preview-wrapper position-relative existing_images">

                                        <object id="myPdf" type="application/pdf" data={`${process.env.REACT_APP_BACKEND_BASE_URL}/uploads/${folder}/original/${ele.document}`} height={'300px'} width={'100%'} style={{ margin: "0 auto" }}> </object>



                                    </p>
                                </a>
                            </Grid>
                        )
                    }
                    else {
                        return (
                            <Grid item sm={6} md={3}>
                                <span className="close-btn"
                                >
                                    <CheckSvg fill={ele.verification_status ? "green" : "red"} onClick={() => {
                                        updateDocumentVerificationStatus({
                                            document_id: ele._id,
                                            status: !ele.verification_status
                                        })
                                    }} />

                                </span>
                                <a href={`${process.env.REACT_APP_BACKEND_BASE_URL}/uploads/${folder}/original/${ele.document}`} target="_blank">
                                    <p className="img-preview-wrapper position-relative existing_images">
                                        <img src={`${process.env.REACT_APP_BACKEND_BASE_URL}/uploads/${folder}/original/${ele.document}`} style={{ width: "100%" }} alt="preview" />

                                    </p>
                                </a>
                            </Grid>
                        )
                    }

                })
                    : null
            }
        </Grid>

    )
}