import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog({ open, setOpen, handleAgree }) {

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className='modal_title' id="alert-dialog-title">
          {"Are your sure you want to delete?"}
        </DialogTitle>
        <DialogContent className='modal_body'>
          <DialogContentText id="alert-dialog-description">
            If you click on Agree, than your data would be deleted.
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            If you click on cancel, than your data would not be deleted.
          </DialogContentText>
        </DialogContent>
        <DialogActions className='modal_footer'>
          <button className='btn_cancel' onClick={handleClose}>Cancel</button>
          <button className='okay_cancel' onClick={handleAgree} autoFocus>
            Agree
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}