import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react"
import { ReactComponent as CloseSvg } from "../../images/cross-mark-button-svgrepo-com (1).svg"
import { ReactComponent as OpenInNewTabSvg } from "../../images/icons8-new-tab.svg"
import AlertDialog from "../Dialog/Alert";


export const SelectedImagesWithPreview = ({ folder, label, fieldName, formik, setFieldImages, fieldImages, deletedImages, setDeletedImages, existingImages, setExistingImages, ...props }: any) => {
    const [imageFiles, setImageFiles] = useState([]);
    const [images, setImages] = useState<any>([])

    const changeHandler = (e: any) => {
        const { files } = e.target;

        const validImageFiles: any = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            validImageFiles.push({ file: file, file_type: file.type });

        }
        if (validImageFiles.length) {
            setImageFiles(validImageFiles);
            return;
        }
        alert("Selected images are not of valid type!");
    };


    useEffect(() => {
        const fileReaders: any = [];
        let isCancel = false;
        if (imageFiles.length) {
            const promises = imageFiles.map(({ file, file_type }) => {
                return new Promise((resolve, reject) => {
                    const fileReader = new FileReader();
                    fileReaders.push(fileReader);
                    fileReader.onload = (e) => {
                        const { result }: any = e.target;
                        if (result) {
                            resolve({ dataUrl: result, file_type: file_type });
                        }
                    }
                    fileReader.onabort = () => {
                        reject(new Error("File reading aborted"));
                    }
                    fileReader.onerror = () => {
                        reject(new Error("Failed to read file"));
                    }
                    fileReader.readAsDataURL(file);
                })
            });
            Promise
                .all(promises)
                .then((images: any) => {
                    if (!isCancel) {
                        setImages(images);
                    }
                })
                .catch(reason => {
                    console.log(reason);
                });
        };
        return () => {
            isCancel = true;
            fileReaders.forEach((fileReader: any) => {
                if (fileReader.readyState === 1) {
                    fileReader.abort()
                }
            })
        }
    }, [imageFiles]);


    const handleDeleteSelected = (idx: number) => {
        let filteredImages = images.filter((image: any, index: number) => index != idx)

        setImages(filteredImages);

        let data = formik.values[fieldName].filter((ele: any, index: number) => index != idx)

        formik.setFieldValue([fieldName], data)
    }




    const [deleteDialog, setDeleteDialog] = useState(false)
    const [elementId, setElementId] = useState("")

    const handleDeleteModal = async (_id: any) => {
        setDeleteDialog(true)
        setElementId(_id)
    }

    const handleDeleteImages = (doc_id: string) => {
        let filteredData = existingImages.filter((ele: any) => ele._id != doc_id)
        setExistingImages(filteredData)

        setDeleteDialog(false)
        setElementId("")
    }


    return (
        <>
            <div className='form-group'>
                <label>{label}</label>
                <input
                    name={fieldName}
                    type="file"
                    className={
                        'form-control'
                    }
                    accept=".pdf, image/*"
                    multiple={true}
                    onChange={(e: any) => {
                        // formik.handleChange(e)
                        changeHandler(e)
                        setFieldImages(e.target.files)
                    }}
                    {...props}
                />
                <div className="invalid-feedback">
                    {formik.errors[fieldName] && formik.touched[fieldName]
                        ? formik.errors[fieldName]
                        : null}
                </div>
            </div>
            <Grid container spacing={2}>
                {
                    images ? images.map((ele: any, index: number) => {

                        if (ele.file_type == "application/pdf") {
                            return (
                                <Grid item sm={6} md={4}>

                                    <p className="img-preview-wrapper position-relative">

                                        <object id="myPdf" type="application/pdf" data={ele.dataUrl} height={'150px'} width={'100%'} style={{ margin: "0 auto" }}> </object>

                                        <span className="close-btn"
                                            onClick={(e) => handleDeleteSelected(index)}
                                        >
                                            <CloseSvg />
                                        </span>
                                    </p>

                                </Grid>
                            )
                        }
                        else {
                            return (
                                <Grid item sm={6} md={4}>
                                    <p className="img-preview-wrapper position-relative">
                                        <img src={ele.dataUrl} style={{ width: "100%" }} alt="preview" />
                                        <span className="close-btn" onClick={(e) => handleDeleteSelected(index)}>
                                            <CloseSvg />
                                        </span>
                                    </p>

                                </Grid>

                            )
                        }

                    })
                        : null
                }


                {
                    existingImages ? existingImages.map((ele: any, index: number) => {

                        if (ele.document.split(".")[1].toLowerCase() == "pdf") {
                            return (
                                <Grid item sm={6} md={4}>

                                    <span className="close-btn"
                                        onClick={(e) => handleDeleteModal(ele._id)}
                                    >
                                        <CloseSvg />
                                    </span>

                                    <a href={`${process.env.REACT_APP_BACKEND_BASE_URL}/uploads/${folder}/original/${ele.document}`} target="_blank" onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation()
                                    }} >

                                        <span className="open-btn"
                                        >
                                            <OpenInNewTabSvg onClick={() => {
                                                window.open(`${process.env.REACT_APP_BACKEND_BASE_URL}/uploads/${folder}/original/${ele.document}`, '_blank', 'noopener,noreferrer');
                                            }} />

                                        </span>

                                        <p className="img-preview-wrapper position-relative existing_images">

                                            <object id="myPdf" type="application/pdf" data={`${process.env.REACT_APP_BACKEND_BASE_URL}/uploads/documents/original/${ele.document}`} height={'150px'} width={'100%'} style={{ margin: "0 auto" }}> </object>


                                        </p>
                                    </a>
                                </Grid>
                            )
                        }
                        else {
                            return (
                                <Grid item sm={6} md={4}>

                                    <span className="close-btn" onClick={(e) => handleDeleteModal(ele._id)}>
                                        <CloseSvg />
                                    </span>

                                    <a href={`${process.env.REACT_APP_BACKEND_BASE_URL}/uploads/${folder}/original/${ele.document}`} target="_blank" >
                                        <p className="img-preview-wrapper position-relative existing_images">
                                            <img src={`${process.env.REACT_APP_BACKEND_BASE_URL}/uploads/${folder}/thumbnail/${ele.document}`} style={{ width: "100%" }} alt="preview" />

                                        </p>
                                    </a>
                                </Grid>
                            )
                        }

                    })
                        : null
                }


            </Grid >


            <AlertDialog open={deleteDialog} setOpen={setDeleteDialog} handleAgree={() => {
                handleDeleteImages(elementId)
            }} />


        </>
    )
}