

export const imageSelector = (state: any) => {
    const {
        totalImages,
        images,
        image,
        page,
        limit,
        loadingStatus,
        error,
        activeCategories,
        activeSubCategories,
        validationError,
        uploadImageStatusResponse
    } = state.image
    return {
        totalImages,
        images,
        image,
        page,
        limit,
        loadingStatus,
        error,
        activeCategories,
        activeSubCategories,
        validationError,
        uploadImageStatusResponse
    }
}