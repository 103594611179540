import Multiselect from "multiselect-react-dropdown";
import React, { useState } from "react";
interface MultiSelectDropDownProps{
  options:any,
  onSelectUser:any,
  multiselectRef:any

}

export default function MultiSelectDropDown({ options ,onSelectUser ,multiselectRef,...props}:MultiSelectDropDownProps) {
  // console.log(options,"options_____");
  const [selectedValue, setSelectedValue] = useState("");



  function onSelect(selectedList:any, selectedItem:any) {
   
    let selectedId:any=[]
    if(selectedList.length>0){
      // eslint-disable-next-line array-callback-return
      selectedList.map((elx:any)=>{
        selectedId.push(elx.id);
      })
    
    }
    onSelectUser(selectedId);
  }

  function onRemove(selectedList:any, removedItem:any) {
    let selectedId:any=[]
    if(selectedList.length>0){
      // eslint-disable-next-line array-callback-return
      selectedList.map((elx:any)=>{
        selectedId.push(elx.id);
      })
      // selectedId
    }
    onSelectUser(selectedId);

  }
  return (
    <Multiselect
      className=""
       ref={multiselectRef}
      options={options}
      selectedValues={selectedValue} //
      onSelect={onSelect}
      onRemove={onRemove}
      displayValue="name"
    />
  );
}
