import React, { useEffect } from 'react'

export default function Information() {
  useEffect(()=>{
return ()=>{
  
}
  },[])
  return (
   
    <div className="add_image-form1 category-frm">
      <h6 className='confirmation-msg'>
        Password reset link sent your email.
        Please check your email box.

      </h6>
    </div>
  )
}
