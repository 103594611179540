import { BrowserRouter, Routes, Route } from "react-router-dom";
import { MantineProvider } from "@mantine/core";

import "./App.css";
import Home from "./components/Home/Home";
import Signup from "./components/Signup/Signup";
import Login from "./components/Login/Login";
import ProtectedRoute from "./components/ProtectedRoutes/ProtectedRoute";
import Settings from "./components/Setting/Settings";
import Users from "./components/Users/Main";
import Dashboard from "./components/Dashboard/Main";
import Notifications from "./components/Notifications/Notification";

import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import Information from "./components/ForgotPassword/Information";
import PageNotFound from "./components/ForgotPassword/PageNotFound";
import EditUser from "./components/Users/AddEdit";
import ViewUser from "./components/Users/View";

import Properties from "./components/Properties/Main";
import PropertiesAddEdit from "./components/Properties/AddEdit";
import PropertiesView from "./components/Properties/View";

import AuthenticatedAdminLayout from "./Layout/AuthenticatedAdminLayout";
import Categories from "./components/Categories/List";
import CreateCategory from "./components/Categories/Create";
import EditCategory from "./components/Categories/Edit";
import Subscribers from "./components/Subscribers/List";

function App() {
  return (
    <MantineProvider theme={{}}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/signup" element={<Signup />}></Route>
          <Route path="/forgot-password" element={<ForgotPassword />}></Route>
          <Route path="/reset-password" element={<ResetPassword />}></Route>
          <Route path="*" element={<PageNotFound />}></Route>

          <Route path="/info" element={<Information />}></Route>


          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <AuthenticatedAdminLayout ><Dashboard /></AuthenticatedAdminLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/user/addedit"
            element={
              <ProtectedRoute>
                <AuthenticatedAdminLayout ><EditUser /></AuthenticatedAdminLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/user/view"
            element={
              <ProtectedRoute>
                <AuthenticatedAdminLayout ><ViewUser /></AuthenticatedAdminLayout>

              </ProtectedRoute>
            }
          />


          <Route
            path="/users"
            element={
              <ProtectedRoute>
                <AuthenticatedAdminLayout ><Users /></AuthenticatedAdminLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/notification"
            element={
              <ProtectedRoute>
                <Notifications />
              </ProtectedRoute>
            }
          />


          <Route
            path="/properties"
            element={
              <ProtectedRoute>
                <AuthenticatedAdminLayout ><Properties /></AuthenticatedAdminLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/property/addedit"
            element={
              <ProtectedRoute>
                <AuthenticatedAdminLayout ><PropertiesAddEdit /></AuthenticatedAdminLayout>
              </ProtectedRoute>
            }
          />


          <Route
            path="/property/view"
            element={
              <ProtectedRoute>
                <AuthenticatedAdminLayout ><PropertiesView /></AuthenticatedAdminLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/settings/:username"
            element={
              <ProtectedRoute>
                <AuthenticatedAdminLayout ><Settings /></AuthenticatedAdminLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/categories"
            element={
              <ProtectedRoute>
                <AuthenticatedAdminLayout ><Categories /></AuthenticatedAdminLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/categories/create"
            element={
              <ProtectedRoute>
                <AuthenticatedAdminLayout ><CreateCategory /></AuthenticatedAdminLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/categories/edit"
            element={
              <ProtectedRoute>
                <AuthenticatedAdminLayout ><EditCategory /></AuthenticatedAdminLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/subscribers"
            element={
              <ProtectedRoute>
                <AuthenticatedAdminLayout ><Subscribers /></AuthenticatedAdminLayout>
              </ProtectedRoute>
            }
          />

        </Routes>

      </BrowserRouter>
    </MantineProvider>
  );
}

export default App;
