import axios from 'axios'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Information from './Information'

export default function ForgotPassword() {
  const [show, setShow] = useState(true)

  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      email: ""
    },
    // validationSchema: {
    //   email: ""
    // },
    onSubmit: async (values) => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/forgotPassword`, values)

        console.log(res);
        if (res.status === 200) {
          toast.success(res.data.message)
          navigate('/info')
          setShow(false)
        }else{
          toast.error("Somthing went wrong.")
        }
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message)

      }

    }
  })


  return (

    <div className="add_image-form1 category-frm">

    
          <form className='add-category' onSubmit={formik.handleSubmit}>
            <div className="h2_div">
              <h2 className="text-center">
                Forgot Password
              </h2>
            </div>
            <div className="flex-fg">
              <div className='form-group'>
                <label>Email</label>
                <input
                  name="email"
                  type="text"
                  placeholder="Enter email"
                  className={
                    'form-control' +
                    (formik.errors.email && formik.touched.email
                      ? ' is-invalid'
                      : '')
                  }
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
                <div className="invalid-feedback">
                  {formik.errors.email && formik.touched.email
                    ? formik.errors.email
                    : null}
                </div>
              </div>

            </div>

            <button type="submit" className='btn main_btn1 text-white mt-4 sbmt'>Submit</button>
            {/* <Link to="/forgot-password" className='forget-pass'>Forget Password</Link> */}
          </form>
      


    </div>
  )
}
