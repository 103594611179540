import Login from "../Login/Login";

function Home() {
  return (
    <div>
      <Login />
    </div>
  );
}

export default Home;
