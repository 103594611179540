

export const userSelector = (state: any) => {
    const {
      totalUsers,
      users,
      page,
      limit,
      loadingStatus,
      error,
      isLoggedIn,
      user
    } = state.user
    return {
      totalUsers,
      users,
      page,
      limit,
      loadingStatus,
      error,
      isLoggedIn,
      user
    }
  }