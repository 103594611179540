import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const api_url = process.env.REACT_APP_BACKEND_API_BASE_URL;

export const sendNotificationThunk = createAsyncThunk(
  'notification',
  async (body: any, { rejectWithValue }) => {
    try {
      let token = body.token;
      console.log("kjdgusghjsgfyudfe",body.token);
      // delete body.token
      const data = await axios.post(`${api_url}/notification/sendNotification`,body, { 'headers': { 'Authorization': `${token}` }})
      

      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
