import { Card } from '@mui/material'
import React from 'react'
interface CardsProps {
  items:any
}
export default function Cards({items}:CardsProps) {


  
  return (
    <div className="dashboard_inner_box">
      <h1>{items.label} </h1>
      <h3>{items.value} </h3>
    {/* <button className='btn'>Show</button> */}
      </div>
  )
}
