export const subscriberSelector = (state: any) => {
    const {
        totalSubscribers,
        subscribers,
        page,
        limit,
        loadingStatus,
        error,
        isLoggedIn,
        subscriber
    } = state.subscriber
    return {
        totalSubscribers,
        subscribers,
        page,
        limit,
        loadingStatus,
        error,
        isLoggedIn,
        subscriber
    }
}