
import Sidebar from '../components/Sidebar/Sidebar'
import React from 'react'

const AuthenticatedAdminLayout = ({ children }: any) => {
    return (

        <div className="general-layout">
            <Sidebar />
            {children}
        </div>
    )
}

export default AuthenticatedAdminLayout;
