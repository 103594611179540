import React from 'react'

export default function PageNotFound() {
  return (
    <div>
        <div className="add_image-form1 category-frm">
      <h6 className='confirmation-msg'>
       Page Not Found.

      </h6>
    </div>
    </div>
  )
}
