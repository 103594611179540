
import { useDispatch } from "react-redux";
import { setIsLoggedIn } from "../Redux/userSlice";
import { useNavigate } from 'react-router-dom';

const useAuth = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logoutUser = () => {
        dispatch(setIsLoggedIn());
        localStorage.removeItem("currentToken");
        localStorage.removeItem("userId");
        navigate("/login")
    }

    const checkLoginToken = () => {
        let token = localStorage.getItem("currentToken")
        if (!token) {
            logoutUser()
        }
        else {
            return token
        }
    }

    return {
        logoutUser,
        checkLoginToken,
    };

}



export default useAuth;

