import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import axios from "axios";
import { useLocation, useNavigate } from 'react-router-dom';

import { SelectedImagesWithPreview } from "../SelectedImagesWithPreview";
import { Grid } from "@mui/material";
import { HashLoader } from "react-spinners";
import useAuth from "../../utils";
import ReactFlagsSelect from "react-flags-select";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { getPropertyDetailsThunk } from '../../Redux/Thunks/propertiesThunk';
import StringArrayInput from '../Custom/StringArrayInput';
import { PropertiesImages } from './PropertiesImages';
import { ViewImages } from './ViewImages';
import { getCategoriesThunk } from '../../Redux/Thunks/categoryThunk';
import { categorySelector } from "../../Redux/Selectors/categorySelector";



const imagefolder = "properties"

const AddEditMain = () => {
    const location = useLocation();
    const navigate = useNavigate()

    const dispatch = useDispatch()

    const [addMode, setAddMode] = useState(true)

    const [isLoading, setLoading] = useState(false);

    const { checkLoginToken, logoutUser } = useAuth();

    const [existingPropertyImages, setExistingPropertyImages] = useState([])
    const [existingPropertyDocuments, setExistingPropertyDocuments] = useState([])
    const [existingGovernmentProofDocuments, setExistingGovernmentProofDocuments] = useState([])
    const [featuredImage, setFeaturedImage] = useState<any>("")
    const [selectedFeaturedImage, setSelectedFeatureImage] = useState<any>("")
    const { categories, totalCategories }: any = useSelector(categorySelector)

    useEffect(() => {
        getCategories();
    }, [])

    const getCategories = async () => {
        const resp: any = await dispatch(getCategoriesThunk({ page: 1, limit: 1000, token: checkLoginToken() }));
    }

    useEffect(() => {

        (async () => {
            try {
                if (location && location.state && Object.entries(location.state).length > 0) {

                    let token = checkLoginToken()
                    let response: any = await dispatch(getPropertyDetailsThunk(
                        {
                            property_id: location.state._id,
                            token: token
                        }
                    ))

                    console.log("response---", response)


                    if (response.payload.response && response.payload.response.status) {
                        if (response.payload.response.status == 401) {
                            setTimeout(() => {

                                setLoading(false)
                            }, 500);
                            logoutUser()
                        }
                        toast.error(response.payload.response.data.message)
                    }

                    else if (response.payload.status == 200 && response.payload.data.body) {
                        setTimeout(() => {

                            setLoading(false)
                        }, 500);

                        // console.log("response----->", response)
                        let userObj = response.payload.data.body;
                        // console.log("userObj---->", userObj)
                        try {



                            formik.setValues({
                                name: userObj.name || "",
                                description: userObj.description || "",
                                property_type: userObj.property_type || "",
                                funding_status: userObj.funding_status || "",

                                location: userObj.location || "",
                                address: userObj.address || "",
                                postal_code: userObj.postal_code || "",
                                city: userObj.city || "",
                                country: userObj.country || "",
                                latitude: userObj.latitude || "",
                                longitude: userObj.longitude || "",

                                property_value: parseFloat(userObj.property_value) || 0,
                                yearly_return_rate: parseFloat(userObj.yearly_return_rate) || 0,
                                anticipated_monthly_rent: parseFloat(userObj.anticipated_monthly_rent) || 0,
                                anticipated_annual_cash_flow: parseFloat(userObj.anticipated_annual_cash_flow) || 0,
                                fractional_investment: parseFloat(userObj.fractional_investment) || 0,
                                minimum_investment_amount: parseFloat(userObj.minimum_investment_amount) || 0,
                                investment_duration: userObj.investment_duration || "",
                                fees_rate: parseFloat(userObj.fees_rate) || 0,
                                fees: parseFloat(userObj.fees) || 0,
                                distributed_revenues_amount: parseFloat(userObj.distributed_revenues_amount) || 0,

                                features: userObj.features && Array.isArray(userObj.features) && userObj.features.map((ele: any) => ele.feature) || [],

                                _id: userObj._id || ""
                            })
                        }
                        catch (err) {
                            console.log('err', err)
                        }


                        setExistingPropertyImages(userObj.property_images)
                        setExistingPropertyDocuments(userObj.property_documents)
                        setExistingGovernmentProofDocuments(userObj.government_proof)


                        setAddMode(false)
                    }


                }
            } catch (err) {
                console.error(err);
            }
        })();


    }, [])

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Username is required"),

        description: Yup.string().required("Description is required"),
        property_type: Yup.string().required("Property type is required"),
        funding_status: Yup.string().required("Funding Status is required"),

        location: Yup.string().required("Native Country is required"),
        address: Yup.string().required("Address is required"),
        postal_code: Yup.string().required("Postal Code is required"),
        city: Yup.string().required("City is required"),
        country: Yup.string().required("Country is required"),
        latitude: Yup.string().required("Latitude is required"),
        longitude: Yup.string().required("Longitude is required"),

        property_value: Yup.number().required("Property Value is required"),
        yearly_return_rate: Yup.number().required("Yearly Return Rate is required"),
        anticipated_monthly_rent: Yup.number().required("Anticipated Monthly Rent is required"),
        anticipated_annual_cash_flow: Yup.number().required("Anticipated Annual Cash Flow is required"),
        fractional_investment: Yup.number().optional(),
        minimum_investment_amount: Yup.number().required("Minimum Investment Amount is required"),
        investment_duration: Yup.string().required("Investment Duration is required"),
        fees_rate: Yup.number().required("Property Fees rate is required"),

        features: Yup.array().of(Yup.string()).min(1, "Atleast one feature should be entered").required("Features are required"),

        ...(addMode ? {
            property_images: Yup.array()
                .min(1, 'At least one document is required')
                .of(
                    Yup.mixed()
                        .test(
                            'fileSize',
                            'File size is too large1',
                            (value) => value && value.size <= 1024 * 1024 * 5 // 5MB
                        )
                        .test(
                            'fileType',
                            'Unsupported file type',
                            (value) =>
                                value &&
                                (value.type.startsWith('image/') || value.type === 'application/pdf')
                        )
                ),
        } : {}),

        ...(addMode ? {
            property_documents: Yup.array()
                .min(1, 'At least one document is required')
                .of(
                    Yup.mixed()
                        .test(
                            'fileSize',
                            'File size is too large1',
                            (value) => value && value.size <= 1024 * 1024 * 5 // 5MB
                        )
                        .test(
                            'fileType',
                            'Unsupported file type',
                            (value) =>
                                value &&
                                (value.type.startsWith('image/') || value.type === 'application/pdf')
                        )
                ),
        } : {}),

        ...(addMode ? {
            government_proof: Yup.array()
                .min(1, 'At least one document is required')
                .of(
                    Yup.mixed()
                        .test(
                            'fileSize',
                            'File size is too large1',
                            (value) => value && value.size <= 1024 * 1024 * 5 // 5MB
                        )
                        .test(
                            'fileType',
                            'Unsupported file type',
                            (value) =>
                                value &&
                                (value.type.startsWith('image/') || value.type === 'application/pdf')
                        )
                ),
        } : {}),

    });


    const formik: any = useFormik({
        enableReinitialize: true,
        initialValues: {

            name: "",
            description: "",
            property_type: "",
            funding_status: "",

            property_images: [],
            property_documents: [],
            government_proof: [],

            location: "",
            address: "",
            postal_code: "",
            city: "",
            country: "",
            latitude: "",
            longitude: "",
            property_value: 0,
            yearly_return_rate: 0,
            anticipated_monthly_rent: 0,
            anticipated_annual_cash_flow: 0,
            fractional_investment: 0,
            minimum_investment_amount: 0,
            fees_rate: 0,
            fees: 0,
            distributed_revenues_amount: 0,
            investment_duration: "",
            features: [],

            _id: ""
        },
        validateOnChange: true,
        validationSchema: validationSchema,

        onSubmit: async (data, { resetForm }) => {

            await handleCreateProperty(data)
            if (addMode) {
                // setFile(null)
                // setFileDataURL(null)

                // resetForm()
                // navigate("/users")
            }
        }
    });






    const handleCreateProperty = async (data: any) => {
        try {
            let token = checkLoginToken()

            let payload: any = { ...data }


            if (data && data.property_images) {
                let formData = new FormData()

                formData.append("folder", imagefolder);
                data.property_images.forEach((ele: any) => {
                    formData.append("image", ele);
                })

                let resp = await axios.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/uploadImage`, formData, { 'headers': { 'Authorization': `Bearer ${token}` } })



                if (resp.statusText == "OK") {
                    let filenames = resp.data.body.filenames;
                    payload.property_images = filenames;
                }
            }

            if (data && data.property_documents) {
                let formData = new FormData()

                formData.append("folder", imagefolder);
                data.property_documents.forEach((ele: any) => {
                    formData.append("image", ele);
                })

                let resp = await axios.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/uploadImage`, formData, { 'headers': { 'Authorization': `Bearer ${token}` } })
                if (resp.statusText == "OK") {
                    let filenames = resp.data.body.filenames;
                    payload.property_documents = filenames;
                }
            }


            if (data && data.government_proof) {
                let formData = new FormData()

                formData.append("folder", imagefolder);
                data.government_proof.forEach((ele: any) => {
                    formData.append("image", ele);
                })

                let resp = await axios.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/uploadImage`, formData, { 'headers': { 'Authorization': `Bearer ${token}` } })
                if (resp.statusText == "OK") {
                    let filenames = resp.data.body.filenames;
                    payload.government_proof = filenames;
                }
            }



            payload.property_images = [...existingPropertyImages.map((ele: any, index: number) => {
                return {
                    image: ele.image,
                    featured: selectedFeaturedImage == ele.image ? true : false
                }
            }), ...(payload.property_images || []).map((ele: any, index: number) => {
                return {
                    image: ele,
                    featured: featuredImage === index ? true : false
                }
            })]


            payload.property_documents = [...existingPropertyDocuments.map((ele: any) => ele.document), ...(payload.property_documents || [])]

            payload.government_proof = [...existingGovernmentProofDocuments.map((ele: any) => ele.document), ...(payload.government_proof || [])]

            // payload.featuredImage = featuredImage;

            // payload.selectedFeaturedImage = selectedFeaturedImage;


            if (data._id) payload._id = data._id;

            let response: any = await axios.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/properties/createUpdateProperty`,
                payload, { 'headers': { 'Authorization': `Bearer ${token}` } })

            // console.log("response----", response);


            if (response.status === 200) {
                toast.success(response.data.message)
                navigate("/properties")
            } else {
                toast.error("Something went wrong.")
            }


        } catch (err) {
            console.log("err", err)
            toast.error(err.response.data.message)

            navigate("/property/addedit")
        }
    };

    // console.log("formik.values", formik.values)



    const handleFloatInputChange = (e: any) => {
        let ifChanged = false
        const { value } = e.target;

        const regex = /^\d*\.?\d{0,2}$/;

        if (regex.test(value) || value === '' || /^\d+$/.test(value)) {
            // Update the state only if the input is valid
            formik.handleChange({ ...e, value: value })
            ifChanged = value
        }
        return ifChanged;

    }


    return (
        <>
            {
                !isLoading ? (
                    <div className="main_layout">

                        <h2>
                            {addMode ? "Add" : "Edit"} Property
                        </h2>
                        <div className="add_edit_box">
                            <form className='add-category' onSubmit={formik.handleSubmit}>
                                <div className="flex-fg">


                                    <PropertiesImages
                                        fieldName={"property_images"}
                                        label={"Property Images"}
                                        formik={formik}
                                        setFieldImages={(e: any) => {
                                            console.log("e--", e)
                                            formik.setFieldValue("property_images", e ? Array.from(e) : e)
                                        }}
                                        fieldImages={formik?.values?.property_images}
                                        existingImages={existingPropertyImages}
                                        setExistingImages={setExistingPropertyImages}

                                        featuredImage={featuredImage}
                                        setFeaturedImage={setFeaturedImage}
                                        selectedFeaturedImage={selectedFeaturedImage}
                                        setSelectedFeatureImage={setSelectedFeatureImage}

                                    />


                                    <SelectedImagesWithPreview
                                        fieldName={"property_documents"}
                                        label={"Passport Proof Documents"}
                                        formik={formik}
                                        setFieldImages={(e: any) => {
                                            console.log("e--", e)
                                            formik.setFieldValue("property_documents", e ? Array.from(e) : e)
                                        }}
                                        fieldImages={formik?.values?.property_documents}
                                        existingImages={existingPropertyDocuments}
                                        setExistingImages={setExistingPropertyDocuments}
                                        folder={imagefolder}
                                    />


                                    <SelectedImagesWithPreview
                                        fieldName={"government_proof"}
                                        label={"Government Proof Documents"}
                                        formik={formik}
                                        setFieldImages={(e: any) => {
                                            console.log("e--", e)
                                            formik.setFieldValue("government_proof", e ? Array.from(e) : e)
                                        }}
                                        fieldImages={formik?.values?.government_proof}
                                        existingImages={existingGovernmentProofDocuments}
                                        setExistingImages={setExistingGovernmentProofDocuments}
                                        folder={imagefolder}
                                    />





                                    <div className='form-group'>
                                        <label>Property Name</label>
                                        <input
                                            name="name"
                                            type="text"
                                            placeholder="Enter Property Name"
                                            className={
                                                'form-control' +
                                                (formik.errors.name && formik.touched.name
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={formik.handleChange}
                                            value={formik.values.name}
                                        />
                                        <div className="invalid-feedback">
                                            {formik.errors.name && formik.touched.name
                                                ? formik.errors.name
                                                : null}
                                        </div>
                                    </div>


                                    <div className='form-group'>
                                        <label>Description</label>
                                        <input
                                            name="description"
                                            type="text"
                                            placeholder="Enter Description"
                                            className={
                                                'form-control' +
                                                (formik.errors.description && formik.touched.description
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={formik.handleChange}
                                            value={formik.values.description}
                                        />
                                        <div className="invalid-feedback">
                                            {formik.errors.description && formik.touched.description
                                                ? formik.errors.description
                                                : null}
                                        </div>
                                    </div>



                                    <div className='form-group'>
                                        <label>Property Type</label>
                                        <select
                                            name="property_type"
                                            placeholder="Select Property Type"
                                            className={
                                                'form-control' +
                                                (formik.errors.property_type && formik.touched.property_type
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={formik.handleChange}
                                            value={formik.values.property_type}
                                        >
                                            <option value={""}>
                                                Select Property Type
                                            </option>
                                            {
                                                categories.length > 0 && categories.map((item: any) => {
                                                    return (
                                                        <option value={item?._id}>
                                                            {item?.title}
                                                        </option>
                                                    )
                                                })
                                            }


                                        </select>
                                        <div className="invalid-feedback">
                                            {formik.errors.property_type && formik.touched.property_type
                                                ? formik.errors.property_type
                                                : null}
                                        </div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Funding Status</label>
                                        <select
                                            name="funding_status"
                                            placeholder="Select Funding Status"
                                            className={
                                                'form-control' +
                                                (formik.errors.funding_status && formik.touched.funding_status
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={formik.handleChange}
                                            value={formik.values.funding_status}
                                        >
                                            <option value={""}>
                                                Select Funding Status
                                            </option>

                                            <option value={"fully_funded"}>
                                                Fully Funded
                                            </option>

                                            <option value={"funding_starting_soon"}>
                                                Funding Starting Soon
                                            </option>

                                            <option value={"funding_in_progress"}>
                                                Funding in Progress
                                            </option>


                                        </select>
                                        <div className="invalid-feedback">
                                            {formik.errors.funding_status && formik.touched.funding_status
                                                ? formik.errors.funding_status
                                                : null}
                                        </div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Location</label>
                                        <input
                                            name="location"
                                            type="text"
                                            placeholder="Enter Location"
                                            className={
                                                'form-control' +
                                                (formik.errors.location && formik.touched.location
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={formik.handleChange}
                                            value={formik.values.location}
                                        />
                                        <div className="invalid-feedback">
                                            {formik.errors.location && formik.touched.location
                                                ? formik.errors.location
                                                : null}
                                        </div>
                                    </div>


                                    <div className='form-group'>
                                        <label>Address</label>
                                        <input
                                            name="address"
                                            type="text"
                                            placeholder="Enter Address"
                                            className={
                                                'form-control' +
                                                (formik.errors.address && formik.touched.address
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={formik.handleChange}
                                            value={formik.values.address}

                                        />
                                        <div className="invalid-feedback">
                                            {formik.errors.address && formik.touched.address
                                                ? formik.errors.address
                                                : null}
                                        </div>
                                    </div>


                                    <div className='form-group'>
                                        <label>Postal Code</label>
                                        <input
                                            name="postal_code"
                                            type="text"
                                            placeholder="Enter Postal Code"
                                            className={
                                                'form-control' +
                                                (formik.errors.postal_code && formik.touched.postal_code
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={formik.handleChange}
                                            value={formik.values.postal_code}

                                        />
                                        <div className="invalid-feedback">
                                            {formik.errors.postal_code && formik.touched.postal_code
                                                ? formik.errors.postal_code
                                                : null}
                                        </div>
                                    </div>


                                    <div className='form-group'>
                                        <label>City</label>
                                        <input
                                            name="city"
                                            type="text"
                                            placeholder="Enter City"
                                            className={
                                                'form-control' +
                                                (formik.errors.city && formik.touched.city
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={formik.handleChange}
                                            value={formik.values.city}

                                        />
                                        <div className="invalid-feedback">
                                            {formik.errors.city && formik.touched.city
                                                ? formik.errors.city
                                                : null}
                                        </div>
                                    </div>


                                    <div className='form-group'>
                                        <label>Country</label>
                                        <ReactFlagsSelect
                                            searchable
                                            searchPlaceholder="Search country"
                                            selected={formik.values.country}
                                            onSelect={(code) => formik.setFieldValue("country", code)}
                                        />
                                        <div className="invalid-feedback">
                                            {formik.errors.country && formik.touched.country
                                                ? formik.errors.country
                                                : null}
                                        </div>
                                    </div>




                                    <div className='form-group'>
                                        <label>Location Coordinates</label>

                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <input
                                                    name="latitude"
                                                    type="text"
                                                    placeholder="Enter Latitude"
                                                    className={
                                                        'form-control' +
                                                        (formik.errors.latitude && formik.touched.latitude
                                                            ? ' is-invalid'
                                                            : '')
                                                    }
                                                    onChange={formik.handleChange}
                                                    value={formik.values.latitude}
                                                />
                                                <div className="invalid-feedback">
                                                    {formik.errors.latitude && formik.touched.latitude
                                                        ? formik.errors.latitude
                                                        : null}
                                                </div>
                                            </Grid>
                                            <Grid item xs={8}>

                                                <input
                                                    name="longitude"
                                                    type="text"
                                                    placeholder="Enter Longitude"
                                                    className={
                                                        'form-control' +
                                                        (formik.errors.longitude && formik.touched.longitude
                                                            ? ' is-invalid'
                                                            : '')
                                                    }
                                                    onChange={formik.handleChange}
                                                    value={formik.values.longitude}
                                                />
                                                <div className="invalid-feedback">
                                                    {formik.errors.longitude && formik.touched.longitude
                                                        ? formik.errors.longitude
                                                        : null}
                                                </div>
                                            </Grid>
                                        </Grid>


                                    </div>


                                    <div className='form-group'>
                                        <label>Property Value</label>
                                        <input
                                            name="property_value"
                                            type="number"
                                            placeholder="Enter Property Value"

                                            className={
                                                'form-control' +
                                                (formik.errors.property_value && formik.touched.property_value
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={(e: any) => {
                                                let ifChanged: any = handleFloatInputChange(e);
                                                if (ifChanged) {
                                                    formik.setFieldValue("minimum_investment_amount", 0)
                                                    formik.setFieldValue("fractional_investment", 0)
                                                }
                                            }}
                                            value={formik.values.property_value}
                                        />
                                        <div className="invalid-feedback">
                                            {formik.errors.property_value && formik.touched.property_value
                                                ? formik.errors.property_value
                                                : null}
                                        </div>
                                    </div>


                                    <div className='form-group'>
                                        <label>Anticipated Monthly Rent</label>
                                        <input
                                            name="anticipated_monthly_rent"
                                            type="number"


                                            placeholder="Enter Anticipated Monthly Rent"
                                            className={
                                                'form-control' +
                                                (formik.errors.anticipated_monthly_rent && formik.touched.anticipated_monthly_rent
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={(e: any) => {
                                                let ifChanged: any = handleFloatInputChange(e);
                                                if (ifChanged) {
                                                    formik.setFieldValue("fees", formik.values.fees_rate * parseFloat(ifChanged))
                                                    formik.setFieldValue("distributed_revenues_amount", (parseFloat(ifChanged) - (formik.values.fees_rate * parseFloat(ifChanged))))
                                                }
                                            }}
                                            value={formik.values.anticipated_monthly_rent}
                                        />
                                        <div className="invalid-feedback">
                                            {formik.errors.anticipated_monthly_rent && formik.touched.anticipated_monthly_rent
                                                ? formik.errors.anticipated_monthly_rent
                                                : null}
                                        </div>
                                    </div>



                                    <div className='form-group'>
                                        <label>Property Fees Rate</label>
                                        <input
                                            name="fees_rate"
                                            type="number"


                                            placeholder="Enter Property Fees Rate"
                                            className={
                                                'form-control' +
                                                (formik.errors.fees_rate && formik.touched.fees_rate
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            //onChange={handleFloatInputChange}
                                            onChange={(e: any) => {
                                                let ifChanged: any = handleFloatInputChange(e);
                                                if (ifChanged) {
                                                    formik.setFieldValue("fees", formik.values.anticipated_monthly_rent * parseFloat(ifChanged))
                                                    formik.setFieldValue("distributed_revenues_amount", formik.values.anticipated_monthly_rent - (parseFloat(ifChanged) * formik.values.anticipated_monthly_rent))
                                                }
                                            }}
                                            value={formik.values.fees_rate}
                                        />
                                        <div className="invalid-feedback">
                                            {formik.errors.fees_rate && formik.touched.fees_rate
                                                ? formik.errors.fees_rate
                                                : null}
                                        </div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Fees</label>
                                        <input
                                            name="fees"
                                            type="number"
                                            disabled
                                            placeholder="Fees"
                                            className={
                                                'form-control' +
                                                (formik.errors.fees && formik.touched.fees
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={handleFloatInputChange}
                                            value={formik.values.fees}
                                        />
                                        <div className="invalid-feedback">
                                            {formik.errors.fees && formik.touched.fees
                                                ? formik.errors.fees
                                                : null}
                                        </div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Distributed Revenues</label>
                                        <input
                                            name="distributed_revenues_amount"
                                            type="number"
                                            disabled
                                            placeholder="Enter Property Fees Rate"
                                            className={
                                                'form-control' +
                                                (formik.errors.distributed_revenues_amount && formik.touched.distributed_revenues_amount
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={handleFloatInputChange}
                                            value={formik.values.distributed_revenues_amount}
                                        />
                                        <div className="invalid-feedback">
                                            {formik.errors.distributed_revenues_amount && formik.touched.distributed_revenues_amount
                                                ? formik.errors.distributed_revenues_amount
                                                : null}
                                        </div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Yearly Return Rate</label>
                                        <input
                                            name="yearly_return_rate"
                                            type="number"


                                            placeholder="Enter Yearly Return Rate"
                                            className={
                                                'form-control' +
                                                (formik.errors.yearly_return_rate && formik.touched.yearly_return_rate
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={handleFloatInputChange}
                                            value={(((formik.values.distributed_revenues_amount * 12) / formik.values.property_value) * 100).toFixed(2)}
                                        />
                                        <div className="invalid-feedback">
                                            {formik.errors.yearly_return_rate && formik.touched.yearly_return_rate
                                                ? formik.errors.yearly_return_rate
                                                : null}
                                        </div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Minimum Investment Amount</label>
                                        <input
                                            name="minimum_investment_amount"
                                            type="number"


                                            placeholder="Enter Minimum Investment Amount"
                                            className={
                                                'form-control' +
                                                (formik.errors.minimum_investment_amount && formik.touched.minimum_investment_amount
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={(e: any) => {
                                                let ifChanged = handleFloatInputChange(e);
                                                if (ifChanged) {
                                                    formik.setFieldValue("fractional_investment", 0);
                                                }
                                            }}
                                            value={formik.values.minimum_investment_amount}
                                        />
                                        <div className="invalid-feedback">
                                            {formik.errors.minimum_investment_amount && formik.touched.minimum_investment_amount
                                                ? formik.errors.minimum_investment_amount
                                                : null}
                                        </div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Anticipated Annual Cash Flow</label>
                                        <input
                                            name="anticipated_annual_cash_flow"
                                            type="number"


                                            placeholder="Enter Anticipated Annual Cash Flow"
                                            className={
                                                'form-control' +
                                                (formik.errors.anticipated_annual_cash_flow && formik.touched.anticipated_annual_cash_flow
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={handleFloatInputChange}
                                            value={((((formik.values.minimum_investment_amount / formik.values.property_value) * 100) / 100) * (formik.values.distributed_revenues_amount * 12)).toFixed(2)}
                                        />
                                        <div className="invalid-feedback">
                                            {formik.errors.anticipated_annual_cash_flow && formik.touched.anticipated_annual_cash_flow
                                                ? formik.errors.anticipated_annual_cash_flow
                                                : null}
                                        </div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Fractional Investment</label>
                                        <input
                                            name="fractional_investment"
                                            type="number"


                                            placeholder="Enter Fractional Investment"
                                            className={
                                                'form-control' +
                                                (formik.errors.fractional_investment && formik.touched.fractional_investment
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={(e: any) => {
                                                let ifChanged: any = handleFloatInputChange(e);
                                                if (ifChanged) {
                                                    let fractionalAmount = parseFloat(formik.values.property_value) * parseFloat(ifChanged) / 100;
                                                    formik.setFieldValue("minimum_investment_amount", fractionalAmount)
                                                }
                                            }}
                                            value={formik.values.fractional_investment}
                                        />
                                        <div className="invalid-feedback">
                                            {formik.errors.fractional_investment && formik.touched.fractional_investment
                                                ? formik.errors.fractional_investment
                                                : null}
                                        </div>
                                    </div>



                                    <div className='form-group'>
                                        <label>Investment Duration</label>
                                        <input
                                            name="investment_duration"
                                            type="text"
                                            placeholder="Enter Investment Duration"
                                            className={
                                                'form-control' +
                                                (formik.errors.investment_duration && formik.touched.investment_duration
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={formik.handleChange}
                                            value={formik.values.investment_duration}
                                        />
                                        <div className="invalid-feedback">
                                            {formik.errors.investment_duration && formik.touched.investment_duration
                                                ? formik.errors.investment_duration
                                                : null}
                                        </div>
                                    </div>


                                    <div className='form-group'>
                                        <label>Features</label>
                                        <StringArrayInput values={formik.values.features || []} onChange={(e: any) => {
                                            formik.setFieldValue("features", e)
                                        }} />
                                        <div className="invalid-feedback">
                                            {formik.errors.features && formik.touched.features
                                                ? formik.errors.features
                                                : null}
                                        </div>
                                    </div>


                                </div>

                                <button type="submit" className='btn main_btn1 text-white mt-4'>Submit</button>
                            </form>
                        </div>
                    </div>
                ) : (
                    <div className="spinner" >

                        <HashLoader color="#1864ab" size={75} loading={true} />
                    </div>
                )
            }
        </>


    );
};

export default AddEditMain;
