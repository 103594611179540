import React from "react";
import { Loader } from "rsuite";
export default function LoadingButton() {
  return (
    <>
      <button className="btn main_btn1 text-white mt-4 sbmt" type="button" disabled >
        <div className="spinner-border text-dark" role="status" >
        <Loader style={{width:"27px",height:"17px"}} />
          <span className="visually-hidden">Loading...</span>
        </div>
        {/* Loading... */}
      </button>
    </>
  );
}
