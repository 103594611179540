import { createSlice /* PayloadAction */ } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { sendNotificationThunk } from "./Thunks/notificationThunk";

export interface NotificationState {
  notifications: any;
  notification: Object;
  totalNotifications: any;
  notificationsError: any
  page: any;
  limit: any;
  loadingStatus: any;

}

const initialState: NotificationState = {
  totalNotifications: 1,
  notifications: [],
  notification: {},
  notificationsError: "",
  page: 1,
  limit: 10,
  loadingStatus: "loading",

};


export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    getAll: (state, action) => {
      state.notifications = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      // send notification
      .addCase(sendNotificationThunk.pending, (state, action: any) => {
        state.loadingStatus = "loading";
        state.notificationsError = "";
        state.notification = {};
      })
      .addCase(sendNotificationThunk.fulfilled, (state, action) => {
        state.loadingStatus = "";
        state.notificationsError = ""
        state.notification = {};
      })
      .addCase(sendNotificationThunk.rejected, (state, action: any) => {
        state.loadingStatus = "";
        state.notificationsError = action.payload.response.data.message;
        state.notification = {};

        // toast.error(action.payload.response.data.message)
      })




  }
});

// Action creators are generated for each case reducer function
export const { getAll } =
  notificationSlice.actions;

export default notificationSlice.reducer;
