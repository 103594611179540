import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { createStyles, Navbar, Group, Button } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { Logout } from "tabler-icons-react";

import { RootState } from "../../Redux/store";
import { setIsLoggedIn } from "../../Redux/userSlice";
import { setActiveItem } from "../../Redux/helperSlice";
import { UserInfo } from "../UserInfo/UserInfo";
import { NavigationLinkData } from "../../data/NavigationLinkData";
import { useState } from "react";
import { toast } from "react-toastify";


const useStyles = createStyles((theme, _params, getRef) => {
  const icon = getRef("icon");
  return {
    navbar: {
      backgroundColor: "#858585",
      borderColor: "#858585",
      position: "fixed",
      minHeight: "100%",
      top: 0,
    },
    version: {
      backgroundColor: theme.colors[theme.primaryColor][7],
      color: theme.white,
      fontWeight: 700,
    },
    header: {
      paddingBottom: theme.spacing.md,
      marginBottom: theme.spacing.md * 1.5,
      "@media (max-width: 1300px)": {
        paddingBottom: theme.spacing.sm,
        marginBottom: theme.spacing.sm * 1.5,
      },
    },
    footer: {
      paddingTop: theme.spacing.sm,
      marginTop: theme.spacing.sm,
      paddingBottom: theme.spacing.sm,
      borderTop: `1px solid ${theme.colors.blue[9]}`,
    },
    link: {
      ...theme.fn.focusStyles(),
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      fontSize: theme.fontSizes.md,
      "@media (max-width: 1300px)": {
        fontSize: theme.fontSizes.sm,
      },
      color: theme.white,
      padding: `${theme.spacing.md}px ${theme.spacing.xs}px`,
      borderRadius: theme.radius.sm,
      fontWeight: 500,

      "&:hover": {
        backgroundColor: theme.colors[theme.primaryColor][5],
      },
    },
    linkIcon: {
      ref: icon,
      color: theme.white,
      opacity: 0.75,
      marginRight: theme.spacing.sm,
    },
    linkActive: {
      "&, &:hover": {
        backgroundColor: theme.colors.blue[9],
        [`& .${icon}`]: {
          opacity: 0.9,
        },
      },
    },
    logoutLink: {
      ...theme.fn.focusStyles(),
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      fontSize: theme.fontSizes.sm,
      color: theme.white,
      padding: `${theme.spacing.sm}px ${theme.spacing.sm}px`,
      borderRadius: theme.radius.sm,
      fontWeight: 500,

      "&:hover": {
        backgroundColor: theme.colors[theme.primaryColor][5],
      },
    },
  };
});

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { navLinkData } = NavigationLinkData();

  //Get user data from redux store
  const userData = useSelector((state: RootState) => state.user.user);
  //const userImage = useSelector((state: RootState) => state.user.image);
  const { email, username, image } = userData;
  const imageUrlArray = image ? image.map((image: any) => image.imageUrl) : null;
  const userImage = imageUrlArray ? imageUrlArray[imageUrlArray.length - 1] : null

  //Mantine custom hooks for styling
  const { classes, cx } = useStyles();
  const largeScreen = useMediaQuery("(min-width: 1300px)");
  const [classAbcd, setClassAbcd] = useState(false)
  //sets state for active link
  const active = useSelector((state: RootState) => state.helper.activeItem);
  //Logout function
  const handleLogout = () => {
    // dispatch(setIsLoggedIn());
    dispatch(setActiveItem("Dashboard"));
    localStorage.removeItem("currentToken");
    localStorage.removeItem("username");
    localStorage.removeItem("userId");
    toast.success("Logged out successfully")
    navigate("/login")
  };

  const links = navLinkData.map((item) => (
    <Link
      className={cx(classes.link, {
        [classes.linkActive]: item.label === active,
      })}
      to={item.link}
      key={item.label}
      onClick={(event) => {
        event.preventDefault();
        navigate(item.link);
        dispatch(setActiveItem(item.label));

      }}
    >
      <item.icon className={classes.linkIcon} />
      <span>{item.label}</span>
    </Link>
  ));


  const closeSidbar = () => {
    setClassAbcd(!classAbcd)
    // document.getElementById("sidebar1").classList.add("show-example");
  }

  return (
    <div id="sidebar1" className={classAbcd ? "sidebar1" : ""} >
      <h3 className="mob_title">  <span className="open_menu" onClick={closeSidbar}>
        <img src={require('../../images/menu.svg').default} alt="menu" />
      </span><span className="mob_logo">CrowdFina</span></h3>

      <div className="mob_hide">
        <span className="close_menu" onClick={closeSidbar}>
          <img src={require('../../images/close_menu.svg').default} alt="menu" />
        </span>
        <Navbar
          width={{ sm: largeScreen ? 300 : 200 }}
          p="md"
          className="sidebar_bg"
        >
          <Navbar.Section mt={-45}>

            <Group className="sidebar_header" position="apart"></Group>
            <UserInfo avatar={userImage} name={username} email={email} />
            <hr />
            {links}
          </Navbar.Section>

          <Navbar.Section className={classes.footer}>
            <hr />
            <Button
              variant="subtle"
              size="md"
              className={classes.logoutLink}
              onClick={handleLogout}
            >
              <Logout className={classes.linkIcon} />
              <span>Logout</span>
            </Button>
          </Navbar.Section>

        </Navbar>
        <div className="sidebar_overlay" onClick={closeSidbar}></div>
      </div>
    </div>
  );
};
export default Sidebar;
