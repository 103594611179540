import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import axios from "axios";
import { useLocation, useNavigate } from 'react-router-dom';

import { Grid } from "@mui/material";
import { HashLoader } from "react-spinners";
import useAuth from "../../utils";
import ReactFlagsSelect from "react-flags-select";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { getPropertyDetailsThunk } from '../../Redux/Thunks/propertiesThunk';
import StringArrayInput from '../Custom/StringArrayInput';
import { ViewImages } from './ViewImages';
import { ViewDocuments } from './ViewDocuments';




const imagefolder = "properties"

const View = () => {
    const location = useLocation();

    const dispatch = useDispatch()


    const [isLoading, setLoading] = useState(false);

    const { checkLoginToken, logoutUser } = useAuth();



    const [userObj, setUserObj] = useState<any>(null)



    useEffect(() => {

        (async () => {
            try {
                if (location && location.state && Object.entries(location.state).length > 0) {

                    let token = checkLoginToken()
                    let response: any = await dispatch(getPropertyDetailsThunk(
                        {
                            property_id: location.state._id,
                            token: token
                        }
                    ))

                    console.log("response---", response)


                    if (response.payload.response && response.payload.response.status) {
                        if (response.payload.response.status == 401) {
                            setTimeout(() => {

                                setLoading(false)
                            }, 500);
                            logoutUser()
                        }
                        toast.error(response.payload.response.data.message)
                    }

                    else if (response.payload.status == 200 && response.payload.data.body) {
                        setTimeout(() => {

                            setLoading(false)
                        }, 500);

                        // console.log("response----->", response)
                        let userObj = response.payload.data.body;
                        // console.log("userObj---->", userObj)
                        let cloneObj = {
                            name: userObj.name || "",
                            description: userObj.description || "",
                            property_type: userObj.property_type || "",

                            property_images: userObj.property_images || [],
                            property_documents: userObj.property_documents || [],
                            government_proof: userObj.government_proof || [],


                            location: userObj.location || "",
                            address: userObj.address || "",
                            postal_code: userObj.postal_code || "",
                            city: userObj.city || "",
                            country: userObj.country || "",
                            latitude: userObj.latitude || "",
                            longitude: userObj.longitude || "",

                            property_value: userObj.property_value || "",
                            yearly_return_rate: userObj.yearly_return_rate || "",
                            anticipated_monthly_rent: userObj.anticipated_monthly_rent || "",
                            anticipated_annual_cash_flow: userObj.anticipated_annual_cash_flow || "",
                            minimum_investment_amount: userObj.minimum_investment_amount || "",
                            investment_duration: userObj.investment_duration || "",

                            features: userObj.features && Array.isArray(userObj.features) && userObj.features.map((ele: any) => ele.feature) || [],

                            _id: userObj._id || ""
                        }

                        setUserObj(cloneObj)

                    }


                }
            } catch (err) {
                console.error(err);
            }
        })();


    }, [])



    return (
        <>
            {
                !isLoading ? (
                    <div className="main_layout">

                        <h2>
                            Property Details
                        </h2>
                        <div className="add_edit_box">
                            <form className='add-category'>
                                <div className="flex-fg">


                                    <ViewImages
                                        field_name={"property_images"}
                                        label={"Property Images"}

                                        existingDocuments={userObj?.property_images || []}

                                    />


                                    <ViewDocuments
                                        field_name={"property_documents"}
                                        label={"Property Documents"}

                                        existingDocuments={userObj?.property_documents || []}

                                    />

                                    <ViewDocuments
                                        field_name={"government_proof"}
                                        label={"Government Proof Document"}

                                        existingDocuments={userObj?.government_proof || []}

                                    />



                                    <div className='form-group'>
                                        <label>Property Name</label>
                                        <input
                                            name="name"
                                            type="text"
                                            placeholder="Enter Property Name"
                                            className={
                                                'form-control'
                                            }
                                            // onChange={formik.handleChange}
                                            value={userObj?.name}
                                            disabled={true}
                                        />

                                    </div>


                                    <div className='form-group'>
                                        <label>Description</label>
                                        <input
                                            name="description"
                                            type="text"
                                            placeholder="Enter Description"
                                            className={
                                                'form-control'
                                            }
                                            // onChange={formik.handleChange}
                                            value={userObj?.description}
                                            disabled={true}
                                        />

                                    </div>



                                    <div className='form-group'>
                                        <label>Property Type</label>
                                        <select
                                            name="property_type"
                                            placeholder="Select Property Type"
                                            className={
                                                'form-control'
                                            }

                                            value={userObj?.property_type}
                                            disabled={true}
                                        >
                                            <option value={""}>
                                                Select Property Type
                                            </option>

                                            <option value={"fingle_family_homes"}>
                                                Single Family Homes
                                            </option>

                                            <option value={"buildings"}>
                                                Buildings
                                            </option>

                                            <option value={"vacation_rentals"}>
                                                Vacation Rentals
                                            </option>

                                            <option value={"commercial_properties"}>
                                                Commercial properties
                                            </option>

                                        </select>
                                    </div>


                                    <div className='form-group'>
                                        <label>Funding Status</label>
                                        <select
                                            name="funding_status"
                                            placeholder="Select Funding Status"
                                            className={
                                                'form-control'
                                            }
                                            disabled={true}
                                            value={userObj?.funding_status}
                                        >
                                            <option value={""}>
                                                Select Funding Status
                                            </option>

                                            <option value={"fully_funded"}>
                                                Fully Funded
                                            </option>

                                            <option value={"funding_starting_soon"}>
                                                Funding Starting Soon
                                            </option>

                                            <option value={"funding_in_progress"}>
                                                Funding in Progress
                                            </option>


                                        </select>

                                    </div>

                                    <div className='form-group'>
                                        <label>Location</label>
                                        <input
                                            name="location"
                                            type="text"
                                            placeholder="Enter Location"
                                            className={
                                                'form-control'
                                            }
                                            disabled={true}
                                            value={userObj?.location}
                                        />

                                    </div>


                                    <div className='form-group'>
                                        <label>Address</label>
                                        <input
                                            name="address"
                                            type="text"
                                            placeholder="Enter Address"
                                            className={
                                                'form-control' 
                                            }
                                            disabled={true}
                                            value={userObj?.address}

                                        />

                                    </div>


                                    <div className='form-group'>
                                        <label>Postal Code</label>
                                        <input
                                            name="postal_code"
                                            type="text"
                                            placeholder="Enter Postal Code"
                                            className={
                                                'form-control'
                                            }

                                            value={userObj?.postal_code}
                                            disabled={true}
                                        />

                                    </div>


                                    <div className='form-group'>
                                        <label>City</label>
                                        <input
                                            name="city"
                                            type="text"
                                            placeholder="Enter City"
                                            className={
                                                'form-control'
                                            }
                                            disabled={true}
                                            value={userObj?.city}

                                        />
                                    </div>


                                    <div className='form-group'>
                                        <label>Country</label>
                                        <ReactFlagsSelect
disabled={true}
                                            selected={userObj?.country}
                                            onSelect={(code) => {}}
                                        />
                                    </div>




                                    <div className='form-group'>
                                        <label>Location Coordinates</label>

                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <input
                                                    name="latitude"
                                                    type="text"
                                                    placeholder="Enter Latitude"
                                                    className={
                                                        'form-control'
                                                    }
                                                    disabled={true}
                                                    value={userObj?.latitude}
                                                />
                                            </Grid>
                                            <Grid item xs={8}>

                                                <input
                                                    name="longitude"
                                                    type="text"
                                                    placeholder="Enter Longitude"
                                                    className={
                                                        'form-control'
                                                    }
                                                    disabled={true}
                                                    value={userObj?.longitude}
                                                />

                                            </Grid>
                                        </Grid>


                                    </div>


                                    <div className='form-group'>
                                        <label>Property Value</label>
                                        <input
                                            name="property_value"
                                            type="text"
                                            placeholder="Enter Property Value"
                                            className={
                                                'form-control'
                                            }
                                            disabled={true}
                                            value={userObj?.property_value}
                                        />
                                    </div>

                                    <div className='form-group'>
                                        <label>Yearly Return Rate</label>
                                        <input
                                            name="yearly_return_rate"
                                            type="text"
                                            placeholder="Enter Yearly Return Rate"
                                            className={
                                                'form-control'
                                            }
                                            disabled={true}
                                            value={userObj?.yearly_return_rate}
                                        />
                                    </div>


                                    <div className='form-group'>
                                        <label>Anticipated Monthly Rent</label>
                                        <input
                                            name="anticipated_monthly_rent"
                                            type="text"
                                            placeholder="Enter Anticipated Monthly Rent"
                                            className={
                                                'form-control'
                                            }
                                            disabled={true}
                                            value={userObj?.anticipated_monthly_rent}
                                        />
                                    </div>


                                    <div className='form-group'>
                                        <label>Anticipated Annual Cash Flow</label>
                                        <input
                                            name="anticipated_annual_cash_flow"
                                            type="text"
                                            placeholder="Enter Anticipated Annual Cash Flow"
                                            className={
                                                'form-control'
                                            }
                                            disabled={true}
                                            value={userObj?.anticipated_annual_cash_flow}
                                        />
                                    </div>


                                    <div className='form-group'>
                                        <label>Minimum Investment Amount</label>
                                        <input
                                            name="minimum_investment_amount"
                                            type="text"
                                            placeholder="Enter Minimum Investment Amount"
                                            className={
                                                'form-control'
                                            }
                                            disabled={true}
                                            value={userObj?.minimum_investment_amount}
                                        />
                                    </div>

                                    <div className='form-group'>
                                        <label>Investment Duration</label>
                                        <input
                                            name="investment_duration"
                                            type="text"
                                            placeholder="Enter Investment Duration"
                                            className={
                                                'form-control'
                                            }
                                            disabled={true}
                                            value={userObj?.investment_duration}
                                        />

                                    </div>


                                    <div className='form-group'>
                                        <label>Features</label>
                                        <StringArrayInput mode={"view"} values={userObj?.features || []} onChange={(e: any) => {
                                            // formik.setFieldValue("features", e)
                                        }} />
                                        {/* <div className="invalid-feedback">
                                            {formik.errors.features && formik.touched.features
                                                ? formik.errors.features
                                                : null}
                                        </div> */}
                                    </div>


                                </div>
                            </form>
                        </div>
                    </div>
                ) : (
                    <div className="spinner" >

                        <HashLoader color="#1864ab" size={75} loading={true} />
                    </div>
                )
            }
        </>


    );
};

export default View;
