
import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';

import { useLocation, useNavigate } from 'react-router-dom';

import { setIsLoggedIn } from "../../Redux/userSlice";

import { Grid } from "@mui/material";

import { ViewDocuments } from "./ViewDocuments";
import { getUserDetailsThunk } from "../../Redux/Thunks/userThunk";
import useAuth from "../../utils";
import ReactFlagsSelect from "react-flags-select";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

const imagefolder = "users"

const View = () => {

    const location = useLocation();
    const navigate = useNavigate()

    const dispatch = useDispatch()

    const [isLoading, setLoading] = useState(false);

    const [userObj, setUserObj] = useState<any>(null)
    const { checkLoginToken, logoutUser } = useAuth();


    async function fetchUserDetails() {
        let token = checkLoginToken()
        let response: any = await dispatch(getUserDetailsThunk(
            {
                user_id: location.state._id,
                token: token
            }
        ))


        if (response.payload.response && response.payload.response.status) {
            if (response.payload.response.status == 401) {
                setTimeout(() => {

                    setLoading(false)
                }, 500);
                logoutUser()
            }
            toast.error(response.payload.response.data.message)
        }

        else if (response.payload.status == 200 && response.payload.data.body) {
            setTimeout(() => {

                setLoading(false)
            }, 500);

            let userObj = response.payload.data.body;
            let cloneUserObj = { ...userObj, investment_objectives: (userObj.investment_objectives && userObj.investment_objectives.map((ele: any) => ele.objective)) || [] }
            setUserObj(cloneUserObj)

        }
    }

    useEffect(() => {

        (async () => {
            try {
                if (location && location.state && Object.entries(location.state).length > 0) {

                    fetchUserDetails()
                }
            } catch (err) {
                console.error(err);
            }
        })();


    }, [])


    console.log("cloneUserObj", userObj)


    return (
        <div className="main_layout">

            <h2>
                User Details
            </h2>
            <div className="view_box">

                <div className="flex-fg">

                    <ViewDocuments onStatusUpdation={fetchUserDetails} field_name={"investor_proof_document"} label={"Investor Proof Documents"} user_id={userObj?._id} existingDocuments={userObj?.investor_proof_document || []} folder={imagefolder} />
                    <ViewDocuments onStatusUpdation={fetchUserDetails} field_name={"government_id_proof"} label={"Government Id Proof Documents"} user_id={userObj?._id} existingDocuments={userObj?.government_id_proof || []} folder={imagefolder} />
                    <ViewDocuments onStatusUpdation={fetchUserDetails} field_name={"passport_proof_document"} label={"Passport Proof Documents"} user_id={userObj?._id} existingDocuments={userObj?.passport_proof_document || []} folder={imagefolder} />
                    <ViewDocuments onStatusUpdation={fetchUserDetails} field_name={"address_proof"} label={"Address Proof Documents"} user_id={userObj?._id} existingDocuments={userObj?.address_proof || []} folder={imagefolder} />



                    <div className='form-group'>
                        <label>First name</label>
                        <input
                            name="first_name"
                            type="text"
                            className={
                                'form-control'

                            }

                            value={userObj?.first_name}
                        />

                    </div>


                    <div className='form-group'>
                        <label>Last name</label>
                        <input
                            name="last_name"
                            type="text"
                            className={
                                'form-control'

                            }

                            value={userObj?.last_name}
                        />

                    </div>


                    {
                        // addMode ? (
                        <div className='form-group'>
                            <label>Email</label>
                            <input
                                name="email"
                                type="text"
                                placeholder="Enter Email"
                                className={
                                    'form-control'
                                }
                                value={userObj?.email}
                                disabled={true}
                            />

                        </div>
                        // ) : ""
                    }
                    <div className='form-group'>
                        <label>Phone Number</label>

                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <input
                                    name="country_code"
                                    type="text"
                                    placeholder="Enter Country Code"
                                    className={
                                        'form-control'
                                    }
                                    value={userObj?.country_code}
                                    disabled={true}
                                />

                            </Grid>
                            <Grid item xs={8}>

                                <input
                                    name="phone_number"
                                    type="text"
                                    placeholder="Enter Phone Number"
                                    className={
                                        'form-control'
                                    }
                                    value={userObj?.phone_number}
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>


                    </div>
                    <div className='form-group'>
                        <label>User name</label>
                        <input
                            name="username"
                            type="text"
                            placeholder="Enter Name"
                            className={
                                'form-control'
                            }
                            value={userObj?.username}
                            disabled={true}
                        />
                    </div>



                    <div className='form-group'>
                        <label>Type Of Account</label>
                        <select
                            name="type_of_account"
                            placeholder="Select Type Of Account"
                            className={
                                'form-control'
                            }

                            value={userObj?.type_of_account}
                            disabled={true}

                        >
                            <option value={"individual"}>
                                I am an individual
                            </option>

                            <option value={"professional"}>
                                I am a professional
                            </option>

                        </select>
                    </div>


                    <div className='form-group'>
                        <label>Nationality</label>

                        <ReactFlagsSelect
                            disabled={true}
                            selected={userObj?.nationality}
                            onSelect={(code) => { }}
                        />


                    </div>


                    <div className='form-group'>
                        <label>Date Of Birth</label>
                        <input
                            name="dob"
                            type="date"
                            placeholder="Enter Date Of Birth"
                            className={
                                'form-control'
                            }

                            value={userObj?.dob}
                        />
                    </div>


                    <div className='form-group'>
                        <label>City Of Birth</label>
                        <input
                            name="city_of_birth"
                            type="text"
                            placeholder="Enter City Of Birth"
                            className={
                                'form-control'
                            }
                            value={userObj?.city_of_birth}
                        />

                    </div>

                    <div className='form-group'>
                        <label>Native Country</label>
                        <ReactFlagsSelect

                            selected={userObj?.native_country}
                            onSelect={(code) => { }}
                        />

                    </div>


                    <div className='form-group'>
                        <label>Address</label>
                        <input
                            name="address"
                            type="text"
                            placeholder="Enter Address"
                            className={
                                'form-control'
                            }
                            value={userObj?.address}
                        />
                    </div>


                    <div className='form-group'>
                        <label>Postal Code</label>
                        <input
                            name="postal_code"
                            type="text"
                            placeholder="Enter Postal Code"
                            className={
                                'form-control'
                            }
                            value={userObj?.postal_code}
                        />
                    </div>



                    <div className='form-group'>
                        <label>City</label>
                        <input
                            name="city"
                            type="text"
                            placeholder="Enter City"
                            className={
                                'form-control'
                            }
                            value={userObj?.city}
                        />

                    </div>




                    <div className='form-group'>
                        <label>Country</label>
                        <ReactFlagsSelect

                            selected={userObj?.country}
                            onSelect={(code) => { }}
                        />
                    </div>



                    <div className='form-group'>
                        <label htmlFor="investment-objectives">Investment Objectives</label>

                        <Select
                            name="investment_objectives"
                            label=""
                            // label="Select Investment Objectives"
                            displayEmpty
                            className={
                                'form-control'
                            }

                            value={userObj?.investment_objectives || []}

                            multiple={true}
                            style={{
                                width: "631.11px",
                                // maxWidth: "631.11px",
                                height: "42.84px",
                                // maxHeight: "42.84px"
                            }}
                            inputProps={{ 'aria-label': 'Without label' }}

                            disabled={true}
                        >


                            <MenuItem disabled value="">
                                <em>Select Investment Objectives</em>
                            </MenuItem>

                            <MenuItem value={"obtain_additional_income"}>
                                Obtain additional income
                            </MenuItem>

                            <MenuItem value={"diversify_my_investment"}>
                                Diversify my investment
                            </MenuItem>

                            <MenuItem value={"increase_the_value_of_my_capital"}>
                                Increase the value of my capital
                            </MenuItem>

                            <MenuItem value={"improve_profitability_on_my_investments"}>
                                Improve profitability on my investments
                            </MenuItem>

                        </Select>
                    </div>


                    <div className='form-group'>
                        <label>Investment Horizon</label>
                        <select
                            name="investment_horizon"
                            placeholder="Select Investment Horizon"
                            className={
                                'form-control'
                            }
                            value={userObj?.investment_horizon}
                            disabled={true}
                        >
                            <option value={""}>
                                Select Investment Horizon
                            </option>

                            <option value={"short_term"}>
                                Short Term (Less than 2 years)
                            </option>

                            <option value={"medium_term"}>
                                Medium Term (2 to 5 years)
                            </option>

                            <option value={"long_term"}>
                                Long Term (Over 5 years)
                            </option>

                        </select>
                    </div>

                    <div className='form-group'>
                        <label>Professional Status</label>
                        <select
                            name="professional_status"
                            placeholder="Select Professional Status"
                            className={
                                'form-control'
                            }
                            value={userObj?.professional_status}
                            disabled={true}
                        >
                            <option value={""}>
                                Select Professional Status
                            </option>

                            <option value={"in_activity"}>
                                In activity
                            </option>

                            <option value={"retired"}>
                                Retired
                            </option>

                            <option value={"Student"}>
                                Student
                            </option>

                            <option value={"without_professional_activity"}>
                                Without professional activity
                            </option>

                        </select>

                    </div>

                    <div className='form-group'>
                        <label>Income Currency</label>
                        <select
                            name="income_currency"
                            placeholder="Enter Income Currency"
                            className={
                                'form-control'
                            }

                            value={userObj?.income_currency}
                            disabled={true}
                        >
                            <option value={""}>
                                Select Income Currency
                            </option>

                            <option value={"$"}>
                                $
                            </option>

                            <option value={"€"}>
                                €
                            </option>
                        </select>
                    </div>


                    <div className='form-group'>
                        <label>Annual Income Range</label>
                        <select
                            name="annual_income_range"
                            placeholder="Select Annual Income Range"
                            className={
                                'form-control'
                            }
                            value={userObj?.annual_income_range}
                            disabled={true}
                        >
                            <option value={""}>
                                Select Annual Income Range
                            </option>

                            <option value={"below_20000"}>
                                Below 20,000
                            </option>

                            <option value={"between_20000_and_50000"}>
                                Between 20,000 and 50,000
                            </option>

                            <option value={"between_50000_and_75000"}>
                                Between 50,000 and 75,000
                            </option>

                            <option value={"between_75000_and_100000"}>
                                Between 75,000 and 100,000
                            </option>

                            <option value={"over_100000"}>
                                Over 100,000
                            </option>

                        </select>
                    </div>


                    <div className='form-group'>
                        <label>Total Wealth</label>
                        <select
                            name="estimated_total_wealth"
                            placeholder="Select Total Wealth Range"
                            className={
                                'form-control'
                            }
                            value={userObj?.estimated_total_wealth}
                        >
                            <option value={""}>
                                Select Estimated Total Wealth Range
                            </option>

                            <option value={"below_50000"}>
                                Below 50,000
                            </option>

                            <option value={"between_50000_and_100000"}>
                                Between 50,000 and 100,000
                            </option>

                            <option value={"between_100000_and_200000"}>
                                Between 100,000 and 200,000
                            </option>

                            <option value={"between_200000_and_400000"}>
                                Between 200,000 and 400,000
                            </option>

                            <option value={"over_400000"}>
                                Over 400,000
                            </option>

                        </select>
                    </div>

                </div>

            </div>
        </div>
    );
};

export default View;
