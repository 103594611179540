
import 'react-circular-progressbar/dist/styles.css';
import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from 'react-paginate';
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { ReactComponent as EditSvg } from '../../images/edit-button-svgrepo-com.svg'
import { ReactComponent as ViewSvg } from '../../images/eye-svgrepo-com.svg'
import { ReactComponent as DeleteSvg } from '../../images/delete-svgrepo-com.svg'
import { getSubscribersThunk } from '../../Redux/Thunks/subscriberThunk';
import { subscriberSelector } from "../../Redux/Selectors/subscriberSelector";
import moment from 'moment';
import AlertDialog from "../Dialog/Alert";

const List = () => {
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { subscribers, totalSubscribers }: any = useSelector(subscriberSelector)
    const token = localStorage.getItem('currentToken');
    const limit = 10;

    const pageCount = useMemo(() => {
        const pageCounter = Math.ceil(totalSubscribers / limit);
        return pageCounter;
    }, [totalSubscribers, limit]);

    const [deleteDialog, setDeleteDialog] = useState(false)
    const [elementId, setElementId] = useState("")

    useEffect(() => {
        getlist();
    }, [])

    const getlist = async () => {
        const resp: any = await dispatch(getSubscribersThunk({ page: 1, limit: limit, token: token }));
    }

    const handlePageClick = async (data: any) => {
        console.log(data)
        await dispatch(
            getSubscribersThunk({
                limit: limit,
                page: data.selected + 1,
                token: token,
            })
        );
        // getData(configObject)
    };

    // const handleDeleteModal = async (e: any, _id: any) => {
    //     setDeleteDialog(true)
    //     setElementId(_id)
    // }

    // const handleDeleteCategory = async (id: any) => {
    //     try {
    //         let payload = {
    //             _id: id,
    //             token: token
    //         }
    //         let response: any = await dispatch(deleteCategoryThunk(payload))

    //         if (response.payload?.status === 200) {
    //             toast.success(response.payload?.data.message)
    //             await dispatch(getCategoriesThunk(
    //                 { page: 1, limit: limit, token: token }
    //             ))
    //             setDeleteDialog(false)
    //             setElementId("")
    //         } else {
    //             toast.error("Something went wrong.")
    //         }
    //     } catch (err) {
    //         console.log(err);
    //     }
    // };

    return (
        <>
            <div className="main_layout">
                <h2>Subscribers List

                </h2>
                <div className="table-responsive">
                    <table>
                        <thead>
                            <tr>

                                <th>Email</th>

                                <th>Created At</th>
                                <th>Updated At</th>
                                {/* <th>Action</th> */}

                            </tr>
                        </thead>

                        <tbody>
                            {
                                totalSubscribers > 0 ?
                                    subscribers.map((item: any) => {
                                        return (
                                            <tr key={item?._id}>

                                                <td>{item?.email}</td>

                                                <td>{moment(item?.createdAt).format('YYYY-MM-DD')}</td>
                                                <td>{moment(item?.updatedAt).format('YYYY-MM-DD')}</td>
                                                {/* <td style={{ cursor: "pointer" }}>


                                                    
                                                    <DeleteSvg height={"15px"} style={{ cursor: "pointer" }} onClick={(e) =>
                                                        handleDeleteModal(e, item._id)
                                                    } /> </td> */}
                                            </tr>

                                        )
                                    })
                                    : <tr><td colSpan={3} className='text-center'>No record found</td></tr>
                            }

                        </tbody>
                    </table>
                    {
                        totalSubscribers > 0 && (
                            <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                //    breakLabel={"..."}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination justify-content-center"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"pagination_prev"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"pagination_next"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}

                            />
                        )
                    }

                    {/* <AlertDialog open={deleteDialog} setOpen={setDeleteDialog} handleAgree={() => {
                        handleDeleteCategory(elementId)
                    }} /> */}

                </div>

            </div >
        </>

    );
};

export default List;
