import { createSlice /* PayloadAction */ } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getImagesThunk, addImageThunk, deleteImageThunk, getAllActiveCategoriesThunk, getAllActiveSubCategoriesThunk, getImageDetailsThunk, updateFeatureThunk } from "./Thunks/imageThunk";

export interface ImageState {
    totalImages: any,

    images: any,
    image: any,
    page: any,
    limit: any,
    loadingStatus: any,
    error: any,
    activeCategories: any,
    activeSubCategories: any
    validationError: any,
    uploadImageStatusResponse:any

}

const initialState: ImageState = {
    totalImages: 1,
    images: [],
    image:{},
    page: 1,
    limit: 10,
    loadingStatus: "loading",
    error: false,
    activeCategories: [],
    activeSubCategories: [],
    validationError: true,
    uploadImageStatusResponse:"fulfilled"

};


export const imageSlice = createSlice({
    name: "image",
    initialState,
    reducers: {
        getAll: (state, action) => {
            state.images = action.payload;
        },
        imageEmpty: (state) => {
            state.image = null
        },
        updateValidationError: (state, action) => {
            console.log("action.payload",action.payload)
            state.validationError = action.payload
        }
    },
    extraReducers: (builder) => {
        builder

            // Get Images
            .addCase(getImagesThunk.pending, (state, action: any) => {
                state.loadingStatus = "loading";
                state.error = false;
            })
            .addCase(getImagesThunk.fulfilled, (state, action) => {
                state.loadingStatus = "";
                state.error = false;
                state.images = action.payload.data.body.data;
                state.totalImages = action.payload.data.body.count
            })
            .addCase(getImagesThunk.rejected, (state, action: any) => {
                state.loadingStatus = "";
                state.error = true;
            })


            // Get Image Details
            .addCase(getImageDetailsThunk.pending, (state, action: any) => {
                state.loadingStatus = "loading";
                state.error = false;
            })
            .addCase(getImageDetailsThunk.fulfilled, (state, action) => {
                state.loadingStatus = "";
                state.error = false;
                state.image = action.payload.data.body;
            })
            .addCase(getImageDetailsThunk.rejected, (state, action: any) => {
                state.loadingStatus = "";
                state.error = true;
            })

            //  updateFeatureThunk
            .addCase(updateFeatureThunk.pending, (state, action: any) => {
                state.loadingStatus = "loading";
                state.error = false;
            })
            .addCase(updateFeatureThunk.fulfilled, (state, action) => {
                state.loadingStatus = "";
                state.error = false;
                state.image = action.payload.data.body;
                
            })
            .addCase(updateFeatureThunk.rejected, (state, action: any) => {
                state.loadingStatus = "";
                state.error = true;
            })


            // Get All Active categories
            .addCase(getAllActiveCategoriesThunk.pending, (state, action: any) => {
                state.loadingStatus = "loading";
                state.error = false;
            })
            .addCase(getAllActiveCategoriesThunk.fulfilled, (state, action) => {
                state.loadingStatus = "";
                state.error = false;
                state.activeCategories = action.payload.data.body.data;
            })
            .addCase(getAllActiveCategoriesThunk.rejected, (state, action: any) => {
                state.loadingStatus = "";
                state.error = true;
            })


            // Get All Active Sub categories
            .addCase(getAllActiveSubCategoriesThunk.pending, (state, action: any) => {
                state.loadingStatus = "loading";
                state.error = false;
            })
            .addCase(getAllActiveSubCategoriesThunk.fulfilled, (state, action) => {
                state.loadingStatus = "";
                state.error = false;
                state.activeSubCategories = action.payload.data.body.data;
            })
            .addCase(getAllActiveSubCategoriesThunk.rejected, (state, action: any) => {
                state.loadingStatus = "";
                state.error = true;

                // toast.error(action.payload.response.data.message)
            })


            // Add Image
            .addCase(addImageThunk.pending, (state, action: any) => {
                state.loadingStatus = "loading";
                state.error = false;
                state.image = {};
            })
            .addCase(addImageThunk.fulfilled, (state, action) => {
                state.loadingStatus = "fulfilled";
                state.error = true;
                state.image = {};
                state.uploadImageStatusResponse="fulfilled"
            })
            .addCase(addImageThunk.rejected, (state, action: any) => {
                state.loadingStatus = "rejected";
                state.error = true;
                state.image = {};
            })



            // Delete Image
            .addCase(deleteImageThunk.pending, (state, action: any) => {
                state.loadingStatus = "loading";
                state.error = false;
            })
            .addCase(deleteImageThunk.fulfilled, (state, action) => {
                state.loadingStatus = "";
                state.error = false;
            })
            .addCase(deleteImageThunk.rejected, (state, action: any) => {
                state.loadingStatus = "";
                state.error = true;
            })

    }
});

// Action creators are generated for each case reducer function
export const { getAll, imageEmpty, updateValidationError } =
    imageSlice.actions;

export default imageSlice.reducer;
