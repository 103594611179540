import { configureStore } from "@reduxjs/toolkit";
import { persistCombineReducers } from "reduxjs-toolkit-persist";
import storage from "reduxjs-toolkit-persist/lib/storage";
import autoMergeLevel1 from "reduxjs-toolkit-persist/lib/stateReconciler/autoMergeLevel1";
import userReducer from "../Redux/userSlice";
import helperReducer from "../Redux/helperSlice";
import categoryReducer from "../Redux/categorySlice";
import imageReducer from '../Redux/imageSlice'
import notificationReducer from "./notificationSlice";
import propertySlice from "./propertySlice";
import subscriberSlice from "./subscriberSlice";

const persistConfig = {
  key: "root",
  storage: storage,
  stateReconciler: autoMergeLevel1,
};

const _persistedReducer = persistCombineReducers(persistConfig, {
  user: userReducer,
  helper: helperReducer,
  category: categoryReducer,
  image: imageReducer,
  notification: notificationReducer,
  properties: propertySlice,
  subscriber: subscriberSlice
});
export type RootState = ReturnType<typeof store.getState>;
export const store = configureStore({
  reducer: _persistedReducer,
});
