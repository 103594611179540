import {
  Container, Grid, Text,
  Table,
} from "@mantine/core";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useMediaQuery } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsersThunk, updateUserDetails } from "../../Redux/Thunks/userThunk";
import { userSelector } from "../../Redux/Selectors/userSelector";
import ReactPaginate from 'react-paginate';
import { useNavigate } from "react-router";
import { setIsLoggedIn } from "../../Redux/userSlice";
import { toast } from "react-toastify";
import { ReactComponent as EditSvg } from '../../images/edit-button-svgrepo-com.svg'
import { ReactComponent as ViewSvg } from '../../images/eye-svgrepo-com.svg'
import { ReactComponent as DeleteSvg } from '../../images/delete-svgrepo-com.svg'
import axios from "axios";
import AlertDialog from "../Dialog/Alert";
import { HashLoader } from "react-spinners";
import useAuth from "../../utils";




const imagefolder = "users"

const Main = () => {
  const midScreen = useMediaQuery("(min-width: 1300px)");
  const largeScreen = useMediaQuery("(min-width: 1750px)");
  const [isLoading, setLoading] = useState(false);

  const dispatch = useDispatch()

  const page: any = process.env.REACT_APP_PAGE
  const limit: any = process.env.REACT_APP_LIMIT

  const [selectedPage, setSelectedPage] = useState<any>(parseInt(page))

  const { users, totalUsers }: any = useSelector(userSelector)

  const navigate = useNavigate();
  const { checkLoginToken, logoutUser } = useAuth();


  async function getData(data: any) {

    setLoading(true)
    let token = checkLoginToken()
    let response: any = await dispatch(getUsersThunk(
      {
        page: data.page,
        limit: data.limit,
        token: token
      }
    ))

    if (response.payload.response && response.payload.response.status) {
      if (response.payload.response.status == 401) {
        setTimeout(() => {

          setLoading(false)
        }, 500);

        logoutUser()

        // dispatch(setIsLoggedIn());
        // localStorage.removeItem("currentToken");
        // localStorage.removeItem("userId");
        // navigate("/login")
      }
      toast.error(response.payload.response.data.message)
    }
    else {
      setTimeout(() => {

        setLoading(false)
      }, 500);
      pageCounterr()
    }

  }

  useEffect(() => {
    getData({
      page: selectedPage,
      limit: limit
    })
  }, [])
  const handleEdit = async (item: any) => {

    navigate('/user/addedit', { state: item })

  }

  const handleView = async (item: any) => {

    navigate('/user/view', { state: item })

  }


  const [deleteDialog, setDeleteDialog] = useState(false)
  const [elementId, setElementId] = useState("")

  const handleDeleteModal = async (e: any, _id: any) => {
    setDeleteDialog(true)
    setElementId(_id)
  }

  const handleDeleteUser = async (id: any) => {
    try {

      let token = checkLoginToken()

      let payload = {
        _id: id,
        token: token
      }
      let response: any = await axios.delete(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/users/delete/${id}`,
        { 'headers': { 'Authorization': `${token}` } })
      console.log("res====>", response);
      if (response.status === 200) {
        toast.success(response.data.message)
        await getData({
          page: selectedPage,
          limit: limit
        })

        setDeleteDialog(false)
        setElementId("")
      } else {
        toast.error("Something went wrong.")
      }



    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateUserDetails = async (id: any, field: string, value: any) => {
    try {

      let token = checkLoginToken()
      let response: any = await dispatch(updateUserDetails(
        {
          _id: id,
          [field]: value,
          token: token
        }
      ))

      if (response.payload.response && response.payload.response.status) {
        if (response.payload.response.status == 401) {
          setTimeout(() => {
            setLoading(false)
          }, 500);
          logoutUser()
        }
        toast.error(response.payload.response.data.message)
      }

      else if (response.payload.status == 200 && response.payload.data.body) {
        toast.success(response.payload.data.message)
        await getData({
          page: selectedPage,
          limit: limit
        })
      }


    } catch (err) {
      console.log(err);
    }
  };

  const rows = users.length > 0 && users.map((row: any, index: number) => (
    <tr key={row._id}>
      <td>
        {index + 1}.
      </td>


      <td>{row.username ? row.username : "-"}</td>
      <td>{row.email ? row.email : "-"}</td>

      <td><label className="switch">
        <input type="checkbox" checked={row.status} onChange={() => {

          handleUpdateUserDetails(row._id, "status", !row.status)
        }} />
        <span className="slider round"></span>
      </label>
      </td>

      <td>{row?.createdAt?.split("T")[0]}</td>
      <td>{row?.updatedAt?.split("T")[0]}</td>
      <td style={{ cursor: "pointer" }}>
        <ViewSvg height={"20px"} style={{ cursor: "pointer" }} onClick={() => handleView(row)} />
        <EditSvg height={"15px"} style={{ cursor: "pointer" }} onClick={() => handleEdit(row)} />
        <DeleteSvg height={"15px"} style={{ cursor: "pointer" }} onClick={(e) =>
          handleDeleteModal(e, row._id)
        } /> </td>
    </tr>
  ));

  const [pageCount, setPageCount] = useState(1);

  const pageCounterr = () => {
    const pageCounter: any = Math.ceil(parseInt(totalUsers) / parseInt(limit))
    setPageCount(pageCounter)
  }

  const handlePageClick = async (data: any) => {
    setSelectedPage(+data.selected + 1)
    await getData({ page: +data.selected + 1, limit: 10 })
  }

  return (
    <>
      {
        !isLoading ? (
          <div className="main_layout">
            <h2>Users List
              <button className="btn add_btn" color="warning" onClick={() => {
                navigate("/user/addedit")
              }}>Add User</button>
            </h2>
            <div className="table-responsive">
              <table>
                <thead>
                  <tr>
                    <th>Sr.No</th>

                    <th>Username</th>
                    <th>Email</th>
                    <th>Status</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                    <th>Action</th>

                  </tr>
                </thead>

                <tbody>
                  {(rows && rows.length >= 0) && (
                    rows
                  )
                  }
                </tbody>
              </table>

              {
                users && users.length > 0 && (
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    //    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-center"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"pagination_prev"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"pagination_next"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}

                  />
                )
              }

              <AlertDialog open={deleteDialog} setOpen={setDeleteDialog} handleAgree={() => {
                handleDeleteUser(elementId)
              }} />
            </div>

          </div>
        ) : (
          <div className="spinner" >

            <HashLoader color="#1864ab" size={75} loading={true} />
          </div>
        )
      }



    </>

  );
};

export default Main;
