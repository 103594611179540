

export const propertiesSelector = (state: any) => {
    const {
        property,
        totalProperties,
        properties,
        page,
        limit,
        loadingStatus,
        error,

    } = state.properties
    return {
        property,
        totalProperties,
        properties,
        page,
        limit,
        loadingStatus,
        error,

    }
}