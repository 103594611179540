import React from 'react'
import Cards from './Cards'
import { useEffect, useState } from "react";
import { useMediaQuery } from '@mui/material';
import { Container } from '@mantine/core';
import { userSelector } from '../../Redux/Selectors/userSelector';
import { useDispatch, useSelector } from 'react-redux';
import { imageSelector } from '../../Redux/Selectors/imageSelector';
import { categorySelector } from '../../Redux/Selectors/categorySelector';
import { useNavigate } from 'react-router';
import { setIsLoggedIn } from '../../Redux/userSlice';
import { toast } from 'react-toastify';
// import {} from 'rsuite/'
import DateRangePicker from 'rsuite/DateRangePicker';
import "../../style.css"
import ReactPaginate from 'react-paginate';
import { CSVLink } from "react-csv";
import { getCategoriesThunk } from '../../Redux/Thunks/categoryThunk';
import { getUsersThunk } from '../../Redux/Thunks/userThunk';
import { getImagesThunk } from '../../Redux/Thunks/imageThunk';
import { propertiesSelector } from '../../Redux/Selectors/propertiesSelector';
// import { any } from 'prop-types';

export default function Main() {

  const imagefolder = "images"
  const page: any = process.env.REACT_APP_PAGE
  const limit: any = process.env.REACT_APP_LIMIT

  const { users, totalUsers } = useSelector(userSelector)
  const { images, totalImages } = useSelector(imageSelector)
  const { properties, totalProperties }: any = useSelector(propertiesSelector)
  const { categories, totalCategories }: any = useSelector(categorySelector)


  const [selectedPage, setSelectedPage] = useState<any>(parseInt(page))
  const [datePikcer, setDatePicker] = useState<any>({
    fromDate: "",
    toDate: ""
  })
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [data, setData] = useState([
    {
      label: "Users",
      value: totalUsers
    },
    {
      label: "Properties",
      value: totalProperties
    },
    {
      label: "Categories",
      value: totalCategories
    },

  ])

  function logoutUser() {
    dispatch(setIsLoggedIn());
    localStorage.removeItem("currentToken");
    localStorage.removeItem("userId");
    navigate("/login")
  }

  function checkLoginToken() {
    let token = localStorage.getItem("currentToken")
    if (!token) {
      logoutUser()
    }
    else {
      return token
    }
  }
  useEffect(() => {

    getData({
      page: selectedPage,
      limit: limit
    })
  }, [])
  async function getData(data: any) {
    let token = checkLoginToken()

    await dispatch(getUsersThunk({
      page: data.page,
      limit: data.limit,
      token: token
    }))

  }



  return (
    <>
      {/* main dashbord section */}
      <div className="main_layout dashboard_main_layout">
        <h2>Dashboard</h2>
        <div className="dashboard_flex">

          {
            data.map((item) => {
              return (
                <>
                  <div className='dashboard_box'>
                    <Cards items={item} />
                  </div>
                </>
              )
            })
          }
        </div>

      </div>
    </>
  )
}
