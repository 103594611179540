import { createSlice /* PayloadAction */ } from "@reduxjs/toolkit";
import { deletePropertyDetails, getPropertiesThunk, getPropertyDetailsThunk } from "./Thunks/propertiesThunk";


export interface PropertyState {
    property: any;
    properties: any;
    totalProperties: any;
    page: any;
    limit: any;
    loadingStatus: any;
    error: any;
}

const initialState: PropertyState = {
    property: null,
    properties: [],
    totalProperties: 1,
    page: 1,
    limit: 10,
    loadingStatus: "loading",
    error: false,
};

export const propertySlice = createSlice({
    name: "property",
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder


            // Get Properties
            .addCase(getPropertiesThunk.pending, (state, action: any) => {
                state.loadingStatus = "loading";
                state.error = false;
                state.properties = [];
                state.totalProperties = 1;
            })
            .addCase(getPropertiesThunk.fulfilled, (state, action) => {
                // console.log(" action.payload.data.body.count", action.payload.data.body.count);
                state.loadingStatus = "";
                state.error = false;
                state.properties = action.payload.data.body.data;
                state.totalProperties = action.payload.data.body.count
            })
            .addCase(getPropertiesThunk.rejected, (state, action: any) => {
                state.loadingStatus = "";
                state.error = true;
                state.properties = [];
                state.totalProperties = 1;
            })


            // Get Property Details
            .addCase(deletePropertyDetails.pending, (state, action: any) => {
                state.loadingStatus = "loading";
                state.error = false;

            })

            // Get Property Details
            .addCase(getPropertyDetailsThunk.pending, (state, action: any) => {
                state.loadingStatus = "loading";
                state.error = false;

            })
            .addCase(getPropertyDetailsThunk.fulfilled, (state, action) => {
                state.loadingStatus = "";
                state.error = false;
            })
            .addCase(getPropertyDetailsThunk.rejected, (state, action: any) => {
                state.loadingStatus = "";
                state.error = true;

            })



    }
});

// Action creators are generated for each case reducer function
// export const { setIsLoggedIn, getAllUsers, setSingleUser, setUserGoals } =
//     userSlice.actions;

export default propertySlice.reducer;
